.name-and-title-mobile {
  display: none;
}

@media only screen and (max-width: 420px) {
  .name-and-title-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .name-mobile {
      font-size: 20px;
      margin: 0;
    }

    .title-mobile {
      margin: 0;
      margin-bottom: 5px;
    }
  }
}
