.contact-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 50%;
}

.contact-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // height: 80%;
  z-index: 1;

  .single-contact-container {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: black;
      cursor: pointer;
      margin-left: 3%;

      :visited {
        color: black;
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .contact-container {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
