.name-and-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: sticky;
  height: 100vh;
  top: 0;
  margin-left: 60px;
  text-align: left;
  min-width: 100%;

  h1 {
    margin-bottom: 0;
    margin-top: 10px;
  }

  h3 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 920px) {
  .name-and-title {
    font-size: 12px;
    margin-left: 100px;
  }
}

@media only screen and (max-width: 420px) {
  .name-and-title {
    display: none;
  }
}
