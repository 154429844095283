.hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
  box-shadow: inset 0 0 0 1px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0);
  /* Hack to improve aliasing on mobile/tablet devices */
}
.hover:hover {
  box-shadow: inset 0 0 0 1px darkgrey, 0 0 1px rgba(0, 0, 0, 0);
  .project-1-thumbnail,
  .project-2-thumbnail,
  .project-3-thumbnail,
  .project-4-thumbnail {
    filter: grayscale(0);
    transition-duration: 0.3s;
  }
  /* Hack to improve aliasing on mobile/tablet devices */
}

.projects-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 30px;

  .projects-title {
    margin: 0;
    margin-bottom: 2vh;
  }

  .single-project-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    padding: 1vh 0 1vh 0;
    width: 26vw;
    margin-bottom: 1vh;

    .single-project-title {
      margin: 0;
      margin-bottom: 0.5vh;
    }

    .single-project-info {
      width: 15vw;
    }

    p {
      margin: 0;
    }

    a {
      margin: 0.2vw;
    }

    .single-project-stack,
    .single-project-short-description {
      font-size: 0.85em;
    }

    .single-project-long-description {
      font-size: 0.85em;
    }

    a {
      font-size: 0.85em;
    }
  }

  .project-1-thumbnail,
  .project-2-thumbnail,
  .project-3-thumbnail,
  .project-4-thumbnail {
    width: 120px;
    height: 120px;
    object-fit: cover;
    padding-right: 1.5vw;
    filter: grayscale(0.8);
  }
}

@media only screen and (max-width: 920px) {
  .single-project-container {
    min-width: 50vw;

    .single-project-info {
      min-width: 30vw;
    }
  }
}

@media only screen and (max-width: 420px) {
  .projects-container {
    align-items: center;
    min-width: 100%;

    .single-project-container {
      min-width: 120%;

      .single-project-title {
        font-size: 12px;
      }

      .single-project-info {
        width: 65%;
        font-size: 14px;
      }

      .project-1-thumbnail,
      .project-2-thumbnail,
      .project-3-thumbnail,
      .project-4-thumbnail {
        width: 80px;
        height: 80px;
        padding-right: 0;
        padding-left: 10px;
      }
    }
  }
}
