.about-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.profile-picture {
  height: 170px;
  border-radius: 50%;
  filter: grayscale(0.6);
}

.personal-statement {
  width: 36%;
  text-align: left;
  font-size: 14px;
  margin-top: 1%;
  margin-bottom: 0.3%;
}

.other-info {
  text-align: right;
  font-size: 14px;
  margin-top: 1%;
  margin-bottom: 0.2%;
}

@media only screen and (max-width: 920px) {
  .profile-picture {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .personal-statement {
    min-width: 50vw;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .other-info {
    width: 50vw;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 420px) {
  .about-container {
    align-items: center;

    h1 {
      margin-bottom: 0;
    }

    .personal-statement,
    .other-info {
      width: 100%;
      text-align: left;
      font-size: 14px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
