nav {
  position: sticky;
  height: 100vh;
  top: 0;
  width: 30%;

  li {
    list-style: none;
  }
}

.nav-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2 2 2 2;
  padding: 0 40px 0 40px;
  cursor: pointer;
}

@media only screen and (max-width: 420px) {
  nav {
    width: 100%;
    display: flex;
    justify-content: center;
    display: none;
  }

  .nav-items {
    flex-direction: row;
    font-size: 14px;
    position: sticky;
    top: 0;
    margin: 0;
    padding: 0;

    .home-button {
      display: none;
    }

    li {
      margin-right: 6px;
    }
  }
}
