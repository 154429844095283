@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200&display=swap');

#bg-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  opacity: 40%;
}

.App {
  text-align: center;
  font-family: 'Noto Sans', sans-serif;
  display: flex;
  justify-content: space-between;
  margin: 0;
  height: 100%;

  .nav-item > .active,
  .current {
    border-left: 1px solid grey;
    padding-left: 3px;
    transition-duration: 0.2s;
  }

  li {
    list-style: none;
  }

  .App-link {
    color: #61dafb;
  }

  .nav-and-title-container {
    display: flex;
    width: 25%;
  }

  .sections-container {
    width: 75%;
    z-index: 1;
    margin-right: 1%;
  }

  .section-content,
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 20%;
  }

  .contact {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

// .bg {
//   width: auto;
//   height: auto;
//   background-size: 300% 300%;
//   background-image: linear-gradient(
//     -45deg,
//     #000000 0%,
//     #f2f2f2 25%,
//     #dbdbdb 50%,
//     #434343 100%
//   );
//   animation: AnimateBG 20s ease infinite;
// }

// @keyframes AnimateBG {
//   0% {
//     background-position: 0% 50%;
//   }
//   50% {
//     background-position: 100% 50%;
//   }
//   100% {
//     background-position: 0% 50%;
//   }
// }

@media only screen and (max-width: 920px) {
  .sections-container {
    padding-right: 3vw;

    .section-content,
    .container {
      margin-bottom: 90%;
    }

    .contact {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

@media only screen and (max-width: 420px) {
  #bg-video {
    position: fixed;
    left: 0;
    top: 0;
    opacity: 70%;
  }

  .App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // max-height: 100%;
    // max-width: 100%;

    .nav-and-title-container {
      flex-direction: column;
      height: 12vh;
      width: 100vw;
      position: sticky;
      top: 0;
      padding-top: 10px;
      background-color: rgb(37, 37, 37);
      color: white;
      z-index: 50;
    }

    .nav-item > .active,
    .current {
      border-left: none;
      border-bottom: 1px solid grey;
      padding-bottom: 3px;
      transition-duration: 0.1s;
    }

    .section-content,
    .container {
      margin-bottom: 10%;
    }

    .contact {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    #home {
      display: none;
    }
  }
}
