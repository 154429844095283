.experience-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.single-experience-container {
  border: 1px solid grey;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  z-index: 0;
  margin-bottom: 0.8%;
  padding: 1%;

  .logo-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 1%;
  }

  .company-name {
    margin-top: 0;
    margin-bottom: 0;
  }

  .job-title-date {
    margin-top: 0;
    margin-bottom: 0;
  }

  ul {
    text-align: left;
    margin-top: 0;
  }

  li {
    list-style: square;
    font-size: 0.85em;
  }

  .warner-logo,
  .nettwerk-logo {
    height: 60px;
    width: auto;
    margin-right: 2%;
  }

  .ga-logo {
    height: 60px;
    width: auto;
    margin-right: 2%;
    filter: grayscale(1);
  }
}

@media only screen and (max-width: 920px) {
  .single-experience-container {
    min-width: 50vw;
    border: none;
  }
}

@media only screen and (max-width: 420px) {
  .experience-container {
    align-items: center;

    .single-experience-container {
      width: 120%;
      margin-bottom: 0.8%;
      padding: 1%;

      .company-name {
        font-size: 14px;
      }

      .job-title-date {
        font-size: 12px;
      }

      li {
        font-size: 10px;
      }

      .warner-logo,
      .nettwerk-logo {
        height: 40px;
      }

      .ga-logo {
        height: 40px;
      }
    }
  }
}
